import { Component } from 'preact';

export default class Frame extends Component {
  render () {
    return (
      <div class="frame">
        { this.props.children }
      </div>
    );
  }
}
