import { Component } from 'preact';
import snippets from '/assets/snippets.json';
import { micromark } from 'micromark';
import { language, addLanguageListener, removeLanguageListener, translate } from '../lang';
// import { translate } from '../lang';

export default class Snippet extends Component {
  state = {
    lang: language
  }

  shouldComponentUpdate (nextProps, nextState) {
    return language !== this.state.lang || nextProps.id !== this.props.id;
  }

  componentDidMount () {
    addLanguageListener(this.setLanguage);
  }

  componentWillUnmount () {
    removeLanguageListener(this.setLanguage);
  }

  setLanguage = lang => {
    this.setState({ lang });
  }

  findSnippet (id) {
    const snippet = snippets.find(row => row.snippetId === id);

    if (!snippet) {
      console.error(`Snippet "${id}" not found.`);
      return '';
    }

    return translate({ pt: snippet.snippet_pt, en: snippet.snippet_en });
  }

  render () {
    let snippet = this.findSnippet(this.props.id);

    if (this.props.lower) {
      snippet = snippet.toLowerCase();
    }

    else if (this.props.upper) {
      snippet = snippet.toUpperCase();
    }

    const parsedSnippet = snippet && micromark(snippet.trim());

    if (!parsedSnippet) {
      return null;
    }

    if (this.props.inline) {
      return (
        <span dangerouslySetInnerHTML={{__html: snippet}} class={`snippet ${this.props.class || ''}`} data-id={ this.props.id }>
        </span>
      );
    }

    return (
      <div dangerouslySetInnerHTML={{__html: parsedSnippet}} class={`snippet ${this.props.class || ''}`} data-id={ this.props.id }>
      </div>
    );
  }
}
