import { Component } from 'preact';
import Snippet from './Snippet';

const ASSETS_PATH = '/assets/slideshow/';
const EXTENSION = '.jpg';

const SLIDESHOW_ENTRIES = [
  'fdf-poster',
  'show',
  'noelle-hoop',
  '3-DSCF1067',
  'oshun-gwen',
  // '1-DSCF1039',
  'eden-kaya',
  '2-DSCF1054',
  // '5-DSCF1110',
  // '6-DSCF1160',
  'oshun-hoop',
  // '4-DSCF1097',
  // 'making-story',
  // 'outside-session',

  'circus-02',
  'circus-03',

  'tote',
  'hatchell',
  'goringa',
  'gate-closed',
  'moss-wizard',
  '7-DSCF1192'
  // 'river-fairy',
  // 'fairy-drawing'

];

const IMAGES = SLIDESHOW_ENTRIES.map(filename => ASSETS_PATH + filename + EXTENSION);

const SLIDESHOW_DURATION = 6000;
const TRANSITION_DURATION = 5000;

export function wait (delay) {
  return new Promise(resolve => setTimeout(resolve, delay));
}

export default class Slideshow extends Component {
  state = {
    currentIndex: 0,
    animating: false,
    showDescription: false
  }

  preloadImage = typeof window !== 'undefined' && new Image()
  nextIndex = 1
  changedAt = Date.now()
  playing = true

  componentDidMount () {
    if (this.preloadImage) {
      this.preloadImage.src = IMAGES[this.state.currentIndex];

      this.preloadImage.addEventListener('load', async () => {
        if (!this.playing) {
          return;
        }

        const remainingTime = this.changedAt + SLIDESHOW_DURATION - Date.now();

        if (remainingTime > 0) {
          await wait(remainingTime);
        }

        this.next();
      });
    }
  }

  previous () {
  }

  next = () => {
    // this.setState({ currentIndex: this.nextIndex, animating: true });
    // this.changedAt = Date.now();
    // this.nextIndex += 1;
    // this.nextIndex %= IMAGES.length;
    // this.preloadImage.src = IMAGES[this.nextIndex];
    // setTimeout(() => this.setState({ animating: false }), TRANSITION_DURATION);

    this.setState({ animating: true });
    
    setTimeout(() => {
      this.setState({ currentIndex: this.nextIndex, animating: false });
      this.changedAt = Date.now();
      this.nextIndex += 1;
      this.nextIndex %= IMAGES.length;
      this.preloadImage.src = IMAGES[this.nextIndex];

    }, TRANSITION_DURATION);
  }


  // onClick={ this.toggleDescription } tabIndex="0"
  // toggleDescription = () => {
  //   const { showDescription } = this.state;

  //   this.setState({ showDescription: !showDescription });
  // }

  render () {
    const { currentIndex, animating, showDescription } = this.state;
    
    return (
      <div class="slideshow" data-animating={ animating }>
        <div class="slideshow-deck">
          <article class="slide current">
            <img src={ IMAGES[currentIndex] } class="slideshow-image"/>
          </article>

          <article class="slide next">
            <img src={ IMAGES[this.nextIndex] } class="slideshow-image"/>
              {/* <Snippet class="description" id={`slide_${SLIDESHOW_ENTRIES[this.nextIndex]}`} /> */}
          </article>
        </div>

        {/* showDescription ?
          <Snippet class="description" id={`slide_${SLIDESHOW_ENTRIES[currentIndex]}`} /> :
          null
        */}
      </div>
    );
  }
}
