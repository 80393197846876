const CLIENT_LANGUAGE = typeof window !== 'undefined' && window.navigator.language.slice(0, window.navigator.language.indexOf('-'));
const DEFAULT_LANGUAGE = CLIENT_LANGUAGE === 'pt' ? 'pt' : 'en';
let language = DEFAULT_LANGUAGE;

export { language };

const languageListeners = [];

export function addLanguageListener (fn) {
  languageListeners.push(fn);
}

export function removeLanguageListener (fn) {
  const index = languageListeners.indexOf(fn);
  
  if (index > -1) {
    languageListeners.splice(index, 1);
  }
}

export function updateLanguage (lang) {
  language = lang;

  languageListeners.forEach(fn => fn(lang));
}

export function translate (dictionary) {
  let text = dictionary[language];

  if (!!text) {
    return text;
  }

  const languages = Object.keys(dictionary).filter(l => l !== language);

  for (let l of languages) {
    text = dictionary[l];

    if (!!text) {
      return text;
    }
  }

  return '';
}