import { Component } from 'preact';
import Snippet from './Snippet';
import { language, addLanguageListener, removeLanguageListener, translate } from '../lang';

const DAYS_EN = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
const DAYS_PT = ['Domingo', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Sábado'];
const MONTHS = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'];

function niceDate (date) {
  /*let displayDate = startDate;

  if (isNaN((new Date(startDate)).valueOf())) {
    displayDate = startDate;
  }

  else {
    displayDate = `${startDate.slice(8,10)} ${MONTHS[Number(startDate.slice(5,7) - 1)]} ${startDate.slice(0,4)}`;

    if (endDate) {
      displayDate += ` - ${endDate}`;  
    }

    if (startTime) {
      displayDate += `. ${startTime}${endTime ? ` - ${endTime}` : ''}`;  
    }
     
  }*/


  const d = new Date(date);
  const day = d.getDay();
  const month = d.getMonth();

  const dayText = translate({ pt: DAYS_PT[day], en: DAYS_EN[day] });
  const monthText = MONTHS[month];

  if (!month) {
    debugger;
  }

  return `${dayText} ${d.getDate()} ${monthText}`;
}

export default class EventsTimetable extends Component {
  state = {
    lang: language,
    eventsByDate: {}
  }

  componentDidMount () {
    addLanguageListener(this.setLanguage);

    const { events } = this.props;

    const eventsByDate = events.reduce((result, event) => {
      if (!(event.startDate in result)) {
        result[event.startDate] = {
          // niceDate: niceDate(event.startDate),
          events: []
        };
      }

      result[event.startDate].events.push(event);

      return result;      
    }, {});

    this.setState({ eventsByDate });
  }

  componentWillUnmount () {
    removeLanguageListener(this.setLanguage);
  }

  setLanguage = lang => {
    this.setState({ lang });
  }

  render () {
    const { eventsByDate } = this.state;

    return (
      <div class="EventsTimetable">
        { Object.keys(eventsByDate).sort().map(date =>
          <section>
            <h3>{ niceDate(date) }</h3>

            <table class="events-timetable">
              { eventsByDate[date].events.map(event => {
                const {
                  id,
                  startDate,
                  endDate,
                  startTime,
                  endTime,
                  location_pt,
                  location_en,
                  eventName_pt,
                  eventName_en,
                } = event;

                const locationText = translate({ pt: location_pt, en: location_en });
                const eventName = translate({ pt: eventName_pt, en: eventName_en });

                return (
                  <tr>
                    <td class="event-name"><a href={`#${ id }`}>{ eventName }</a></td>
                    <td class="event-location">{ locationText }</td>
                    <td class="event-starttime">{ startTime }</td>
                    <td class="event-endtime">{ endTime }</td>
                  </tr>
                );
              }) }
            </table>
          </section>
        ) }
      </div>
    );
  }
}
