import { Component } from 'preact';
import { language, updateLanguage, addLanguageListener, removeLanguageListener, translate } from '../lang';
import { micromark } from 'micromark';
import Snippet from './Snippet';

const MONTHS = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

export default class Event extends Component {
  state = {
    lang: language
  }

  shouldComponentUpdate () {
    return language !== this.state.lang;
  }

  componentDidMount () {
    addLanguageListener(this.setLanguage);
  }

  componentWillUnmount () {
    removeLanguageListener(this.setLanguage);
  }

  setLanguage = lang => {
    this.setState({ lang });
  }

  render () {
    const {
      id,
      startDate,
      endDate,
      startTime,
      endTime,
      organisers,
      location_pt,
      location_en,
      eventName_pt,
      eventName_en,
      summary_pt,
      summary_en,
      description_pt,
      description_en,
      cost_pt,
      cost_en
    } = this.props.data;

    let displayDate = startDate;

    if (isNaN((new Date(startDate)).valueOf())) {
      displayDate = startDate;
    }

    else {
      displayDate = `${startDate.slice(8,10)} ${MONTHS[Number(startDate.slice(5,7) - 1)]} ${startDate.slice(0,4)}`;

      if (endDate) {
        displayDate += ` - ${endDate}`;  
      }

      if (startTime) {
        displayDate += `. ${startTime}${endTime ? ` - ${endTime}` : ''}`;  
      }
       
    }

    const locationText = translate({ pt: location_pt, en: location_en });
    const summaryText = micromark(translate({ pt: summary_pt, en: summary_en }));
    const descriptionText = micromark(translate({ pt: description_pt, en: description_en }));
    const eventName = translate({ pt: eventName_pt, en: eventName_en });
    const costText = translate({ pt: cost_pt, en: cost_en });
    const organisersText = organisers.trim();

    return (
      <article class="event" tabindex="0" id={ id }>
        <p><time datetime={`${startDate}${startTime ? 'T' + startTime : ''}`} class="event-date">{ displayDate }</time></p>
        
        <h2>{ eventName }</h2>

        <p dangerouslySetInnerHTML={ {__html: summaryText} }></p>
        
        <div dangerouslySetInnerHTML={ {__html: descriptionText} }></div>

        <p>
          <strong><Snippet id="event_location" inline="true"/></strong>: { locationText }
        </p>
        
        { organisersText ? <p><strong><Snippet id="event_organisers" inline="true"/></strong>: { organisersText }</p> : ''}
        
        { costText ? <p><strong><Snippet id="event_cost" inline="true"/></strong>: { costText }</p> : ''}
      </article>
    );
  }
}
