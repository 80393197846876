import { Component } from 'preact';
import Event from './Event';
import EventsTimetable from './EventsTimetable';
import events from '/assets/events.json';

export default class Events extends Component {
  render () {
    return (
      <div>
        <EventsTimetable events={ events } />

        <div class="events-list">
          { events.map(event => <Event data={event}/>) }
        </div>
      </div>
    );
  }
}
