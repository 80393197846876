import './style';
import Events from './components/Events';
import Snippet from './components/Snippet';
import { Component } from 'preact';
import LanguageSwitcher from './components/LanguageSwitcher';
import Slideshow from './components/Slideshow';
import Frame from './components/Frame';
// import Tickets from './components/Tickets';
// import { language, updateLanguage, addLanguageListener, removeLanguageListener } from './lang';

if (location.pathname !== '/') {
  history.pushState({}, '', '/');
}


export default class App extends Component {
  // componentDidMount () {
    
  // }

  render () {
    return (
      <div>
        <header>
          <h1>
          <a href="/" onClick={event => {
            event.preventDefault();

            if (location.pathname !== '/') {
              history.pushState({}, '', '/');
            }
          }}>Festa da Floresta</a>
          </h1>
        
        <nav>
          <LanguageSwitcher/>
        </nav>
      </header>
  
        <main>
          <section id="info">
              <div class="logo">
                <img src="/assets/logo.svg" />
              </div>

              <Frame>
                <div class="overview">
                  <Snippet id="section-1"/>
                
                  <Snippet id="section-2"/>
        
                  <Snippet id="section-3"/>

                  <Snippet id="get-involved"/>
                </div>
              </Frame>
            </section>

            <section id="slideshow">
              <Slideshow lang={this.state.lang}/>
            </section>

            <section id="events">
              <h2><Snippet id="events" inline="true"/></h2>

              <Events/>
            </section>

            <section id="tickets">
              <div class="tickets-content">
                <h2><Snippet id="tickets_heading"/></h2>

                {/*
                <Snippet id="tickets_info"/>
                <Tickets />
                */}

                <p>Theatre tickets are all <strong>Sold Out</strong>. Thank you for your support.</p>

              </div>
            </section>
          </main>
        </div>
    );
  }
}
