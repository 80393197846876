import { Component } from 'preact';
import { language, updateLanguage, addLanguageListener, removeLanguageListener } from '../lang';

export default class LanguageSwitcher extends Component {
  state = {
    lang: language
  }

  shouldComponentUpdate () {
    return language !== this.state.lang;
  }

  componentDidMount () {
    addLanguageListener(this.setLanguage);
  }

  componentWillUnmount () {
    removeLanguageListener(this.setLanguage);
  }

  setLanguage = lang => {
    this.setState({ lang });
  }

  render () {
    return (
      <span class="language_switcher">
        <input type="image" title="English" src="/assets/uk_flag.svg" style={ language === 'pt' ? '' : 'display:none;' } onClick={ () => updateLanguage('en') }/>
        <input type="image" title="Português" src="/assets/pt_flag.svg" style={ language === 'en' ? '' : 'display:none;' } onClick={ () => updateLanguage('pt') }/>
      </span>
    );
  }
}
